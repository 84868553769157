import React, { useState } from 'react';
import './LandingPage.css';
import Modal from 'react-modal';
import logo from './assets/lummox-logo.png';

const LandingPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
//    fetch('http://localhost:5000/save-email', {
      fetch('/api/save-email', {  // Using relative path
            method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmissionMessage('Thank you for your interest!'); // Show success message
        setModalIsOpen(false); // Close the modal after submission
      })
      .catch((error) => {
        console.error('Error:', error);
        setSubmissionMessage('Submission failed. Please try again.');
      });
  };

  return (
    <div className="landing-page">
      <header className="header">
        <img src={logo} alt="Lummox Logo" className="logo" />
        <h1>Welcome to the <span>Lummox</span> Book Series</h1>
        <p>Practical guides to simplify big ideas for leaders, entrepreneurs, and professionals.</p>
      </header>

      <section className="cta-section">
        <h2>Be the First to Know!</h2>
        <p>Sign up for updates on new releases, exclusive previews, and more.</p>
        <button className="cta-button" onClick={() => setModalIsOpen(true)}>Sign Up for Updates</button>
      </section>

      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} contentLabel="Sign Up Form">
        <h2>Sign Up for Updates</h2>
        <form onSubmit={handleSubmit} className="form-container">
  <div className="form-group">
    <label htmlFor="firstName">First Name:</label>
    <input
      type="text"
      id="firstName"
      name="firstName"
      value={formData.firstName}
      onChange={handleInputChange}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="lastName">Last Name:</label>
    <input
      type="text"
      id="lastName"
      name="lastName"
      value={formData.lastName}
      onChange={handleInputChange}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="email">Email:</label>
    <input
      type="email"
      id="email"
      name="email"
      value={formData.email}
      onChange={handleInputChange}
      required
    />
  </div>
  <div className="form-group">
    <button type="submit" className="submit-button">Submit</button>
  </div>
</form>
<button className="modal-close-button" onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>

      {submissionMessage && <p className="submission-message">{submissionMessage}</p>}

      <footer className="footer">
        <p>© 2024 Lummox Series by Thomas Ward. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
